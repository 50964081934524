import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-subscription',
  templateUrl: './form-subscription.component.html',
  styleUrls: ['./form-subscription.component.css']
})
export class FormSubscriptionComponent implements OnInit {

  currentValue: any = 0

  payment: any = [
    {name: 'Mensal', value: 20},
    {name: 'Anual', value: 240}
  ]

  constructor() { }

  ngOnInit(): void {
  }

  getValue(value: any) {
    this.currentValue = value.value
  }

  submitSubscription() {
    console.log('subscriç\ao')
  }

}
