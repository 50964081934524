<div class="footer container-fluid p-0">
  <div class="footer-body d-flex align-items-center">
    <div class="container d-flex flex-column justify-content-between">
      <div class="row pt-3">
        <div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start">
          <img src="assets/img/logo-oficial-white.png" alt="logo-footer" class="ms-md-0 mt-3 mt-md-0 img-size">
        </div>
        <div class="col-12 col-md-6">
          <div class="links d-flex justify-content-center justify-content-md-end py-4 py-md-0">

          
            <div class="col-4 col-md-2 d-flex justify-content-between">

           
            <a href="mailto:geral@regionaleconomics.pt" target="_blank">
            
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--color2)"
                class="bi bi-envelope icon" viewBox="0 0 16 16">
                <path
                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
              </svg>
            </a>
            
            
            <a href="https://www.instagram.com/regionaleconomics/" target="_blank" class="mx-3">
            
            
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="var(--color2)" viewBox="0 0 448 512"
                class="icon">
                <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg></a>
            
            
            
            <a href="https://www.linkedin.com/company/regional-economics/" target="_blank">
            
            
              <svg width="25" height="25" fill="var(--color2)" aria-hidden="true" focusable="false" data-prefix="fab"
                data-icon="linkedin" class="svg-inline--fa fa-linkedin fa-w-14 icon" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="0000"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z">
                </path>
              </svg> </a>
            
 </div>
            <!-- <div class="d-flex justify-content-start justify-content-md-end align-items-center py-3 py-md-2">

              <p > <a href="https://www.instagram.com/regionaleconomics/"
                  target="_blank">regionaleconomics
                  <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" width="25" height="25"fill="var(--color2)"
                    viewBox="0 0 448 512">
                    <path
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                  </svg></a>
              </p>
            </div>

            <div class="d-flex justify-content-start justify-content-md-end align-items-center py-3 py-md-2">

              <p class="me-2"> <a href="https://www.linkedin.com/company/regional-economics/"
                  target="_blank">linkedin.com/regional-economics</a>
              </p>

              <svg width="25" height="25" fill="var(--color2)" aria-hidden="true" focusable="false" data-prefix="fab"
                data-icon="linkedin" class="svg-inline--fa fa-linkedin fa-w-14" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="0000"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z">
                </path>
              </svg>


            </div> -->

            <!-- <div class="d-flex justify-content-start justify-content-md-end align-items-center py-3 py-md-2">

              <p class="order-2 order-md-1"> <a href="tel:+351969918226">+351 969 918 226</a>
              </p>


              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--color2)" class="bi bi-telephone-fill ms-0 ms-md-2 me-3 me-md-0 icon order-1 order-md-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
            </div> -->

            <!--             <div class="d-flex justify-content-start justify-content-md-end align-items-start py-3 py-md-2">
              <p class="order-2 order-md-1">Rua da Estrada Velha 18 Mundão <br> 3505-581 Viseu </p>

              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--color2)"
                class="bi bi-geo-alt-fill ms-0 ms-md-2 me-3 me-md-0 icon order-1 order-md-2 mt-2" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="credits d-flex flex-column align-items-center justify-content-center">
    <div class="container py-md-2">
      <div class="row d-flex align-items-end justify-content-center">
        <div class="col-12 pb-0 d-flex justify-content-center justify-content-md-start">
          <p class="m-0 text-light">Copyright © 2023 Regional Economics.</p>
        </div>
        <!--  <div class="col-12 col-md-6 d-flex justify-content-start pb-3 pb-md-0 justify-content-md-end order-1 order-md-2">
          <img src="assets/img/c-mbs-dd-reta-white.png" alt="payments" class="img-fluid w-75 w-md-50">
        </div> -->
      </div>

    </div>
  </div>
</div>

