import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {

  showMenu: boolean = false

  constructor(
    public router: Router,
    private scroller: ViewportScroller
  ) {
    
   }

  ngOnInit(): void {
    
  }

  goDown(value: string) {
    this.scroller.scrollToAnchor(value);
    this.showMenu = !this.showMenu
  }

}
