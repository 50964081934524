
<div class="container-fluid bg1 d-flex align-items-center flex-column">

    <div class="container bg-social">
        <div class="d-flex justify-content-end align-items-center my-3 links">
    
               
            <a href="mailto:geral@regionaleconomics.pt" target="_blank">
            
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="var(--color2)"
                class="bi bi-envelope icon" viewBox="0 0 16 16">
                <path
                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
              </svg>
            </a>
            
            
            <a href="https://www.instagram.com/regionaleconomics/" target="_blank" class="mx-3">
            
            
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="var(--color2)" viewBox="0 0 448 512"
                class="icon">
                <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg></a>
            
            
            
            <a href="https://www.linkedin.com/company/regional-economics/" target="_blank">
            
            
              <svg width="25" height="25" fill="var(--color2)" aria-hidden="true" focusable="false" data-prefix="fab"
                data-icon="linkedin" class="svg-inline--fa fa-linkedin fa-w-14 icon" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="0000"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z">
                </path>
              </svg> </a>
            
    </div>
    </div>
    
<!--     <div class="container d-block d-md-none bg-secondary">    
    <img class="d-block d-md-none img-fluid logo" src="assets/img/logo-oficial-update.png" alt="logo">

      <img src="assets/img/portugal-map-mobile.webp" alt="map-mobile" class="img-fluid">
       
    </div> -->


    <div class="container position-relative d-none d-md-flex justify-content-end">
        <img src="assets/img/teste1.webp" alt="portugal-map" class="laptop img-fluid">
        <div class="info position-absolute d-none d-md-block">
          <!--   <h1 class="text-light text-uppercase">Informação económica regional</h1>
            <div class="row justify-content-between">
                <div class="col text-light text-uppercase text-center bg3">Objetiva</div>
                <div class="col mx-4 text-light text-uppercase text-center bg3">Intuitiva</div>
                <div class="col text-light text-uppercase text-center bg3">Acessível</div>
            </div> -->
            <h1 class="text-light">Informação económica regional objetiva, intuitiva e acessível</h1>
        </div>

        <!-- mobile -->

        <!-- <div class="info position-absolute d-block d-md-none">
            <div class="row justify-content-between p-4">
                <div class="col-12 mb-3 text-light text-uppercase text-center bg3">Objetiva</div>
                <div class="col-12 mb-3 text-light text-uppercase text-center bg3">Intuitiva</div>
                <div class="col-12 mb-3 text-light text-uppercase text-center bg3">Acessível</div>
            </div>
        </div> -->
    </div>

   
</div>
