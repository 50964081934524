import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BannerHomeComponent } from './components/banner-home/banner-home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { CaseStudiesComponent } from './components/case-studies/case-studies.component';
import { TeamComponent } from './components/team/team.component';
import { RegionComponent } from './components/region/region.component';
import { TitleLineComponent } from './components/title-line/title-line.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormSubscriptionComponent } from './components/form-subscription/form-subscription.component';
import { HomeComponent } from './components/home/home.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BannerHomeMobileComponent } from './components/banner-home-mobile/banner-home-mobile.component';
import { MenuComponent } from './components/menu/menu.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoadingInterceptor } from './interceptor/loading.interceptor';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'regionaleconomics.pt' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  "palette": {
    "popup": {
      "background": "#FFF",
    },
    "button": {
      "background": "var(--color1)",
    }
  },
  "theme": "classic",
  
  content: {
    message: '<div style="font-size: 14px; line-height: 22px; padding-top: 22px; text-align: justify">A Regional Economics utiliza cookies de terceiros para permitir '
      + 'que o website funcione corretamente, bem como para recolher dados de navegação e preferências para personalizar conteúdo e anúncios para analisar o nosso tráfego. ' 
      + 'Saiba mais sobre a utilização de cookies através da '
      + '<a  href="https://policies.google.com/technologies/cookies?hl=pt-PT" target="_blank" rel="noopener noreferrer" style="color: var(--color1)">Política de Cookies</a>.</div>',
    dismiss: 'ACEITAR',
    link: ''
  },
  enabled: true
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    BannerHomeComponent,
    AboutUsComponent,
    SubscriptionComponent,
    CaseStudiesComponent,
    TeamComponent,
    RegionComponent,
    TitleLineComponent,
    FormSubscriptionComponent,
    HomeComponent,
    ScrollTopComponent,
    BannerHomeMobileComponent,
    MenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
