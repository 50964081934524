

<svg (click)="showMenu = !showMenu" [ngClass]="showMenu ? 'fill-white' : 'fill-black'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-list icon" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg>

<div *ngIf="showMenu" class="menuPage d-flex justify-content-center align-items-start">
    <div class="container text-center" style="margin-top: 20%;">
        <h2 class="text-uppercase my-0" (click)="goDown('about-us')">quem somos</h2>
<!--         <h2 class="text-uppercase my-0" (click)="goDown('subscription')">subscrição</h2>
 -->        <h2 class="text-uppercase my-0" (click)="goDown('region')">a região</h2>
        <h2 class="text-uppercase my-0" (click)="goDown('case-studies')">case studies</h2>
        <h2 class="text-uppercase my-0" (click)="goDown('team')">a equipa</h2>
    </div>
</div>