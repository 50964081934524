import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { listOfPosts } from '../models/list-of-posts';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  url: string = 'https://regional-economics-api.vercel.app/'

  constructor(private http: HttpClient) { }

  getPosts() {
    return this.http.get<listOfPosts>(this.url);
  }

  getNextPage(param: string) {
    const params = new HttpParams().set('after', param);
    return this.http.get<listOfPosts>(this.url, { params })
  }
}
