import { Component, OnInit, Renderer2 } from '@angular/core';
import { InfoService } from 'src/app/services/info.service';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, concat, forkJoin, of } from 'rxjs';
import { Paginas, listOfPosts } from 'src/app/models/list-of-posts';
import { LoadingService } from 'src/app/services/loading.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.css'],
  animations: [
    // animation triggers go here
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('0.5s ease-in', 
                    style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class CaseStudiesComponent implements OnInit {

  listOfPosts: listOfPosts = {
    posts: [],
    paginas: {
      before: '',
      after: ''
    },
    last: false
  }

  constructor(
    public infoService: InfoService,
    private renderer: Renderer2,
    public loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.infoService.getPosts().subscribe(res => {
      this.listOfPosts = res
    })
  }

  nextPage(value: Paginas) {
    if(value.after) {
      this.infoService.getNextPage(value.after).subscribe(res => {
        const mergedPosts = this.listOfPosts.posts.concat(res.posts)
        this.listOfPosts = {posts: mergedPosts, paginas: res.paginas, last: res.last}
      })
    }
  }

  getCarouselTargetId(index: number): string {
    return `#carouselExample${index}`;
  }

}
