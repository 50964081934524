import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-home',
  templateUrl: './banner-home.component.html',
  styleUrls: ['./banner-home.component.css']
})
export class BannerHomeComponent implements OnInit {

  sizeOfScreen:number = 900

  constructor() { }

  ngOnInit(): void {
    this.getSizeOfScreen()
  }

  getSizeOfScreen() {
    this.sizeOfScreen = document.documentElement.clientHeight || window.innerHeight
  }

}
