import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.css']
})
export class ScrollTopComponent implements OnInit {

  constructor(
    private scroller: ViewportScroller
  ) { }

  ngOnInit(): void {
  }

  goTop() {
    this.scroller.scrollToPosition([0,0])
  }
}
