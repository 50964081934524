import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {

  infoRegion = [
    {
      id: 1,
      title: "Dinâmica da atividade económica e das empresas",
      smallText: "A evolução da atividade económica tem um impacto não apenas nos principais rácios financeiros das empresas, mas também no nível de vida da população. Um município que apresenta de forma consistente um crescimento do Mercado de Trabalho, do Volume de Negócios, das Exportações e do Valor Acrescentado gerado pelas suas empresas, será um município mais próximo de alcançar os seus objetivos económicos.",
      description: ['Número de empresas', 'Valor acrescentado das empresas', 'Exportações/ importações', 'Pessoal ao serviço','Volume de negócios','Empreendedorismo']
    },
    {
      id: 2,
      title: "Caraterização do tecido empresarial",
      smallText: "Cada região tem um sistema empresarial com características muito próprias, que dependem dos recursos afetados a cada Setor de Atividade Económica. Conhecer a especialização produtiva da região e as alterações verificadas no tecido empresarial ao longo da última década revela-se essencial para o conhecimento da economia da região.",
      description: ['Composição tecido empresarial', 'Produtividade', 'Especialização produtiva', 'Efeitos intersetoriais']
    },
    {
      id: 3,
      title: "Fatores de atratividade de empresas e pessoas",
      smallText: "As Atividade Económica e a Atratividade dos municípios estão extremamente relacionadas. Regiões com maiores oportunidades de emprego e com remunerações mais elevados tendem a atrair não só mais habitantes, que por sua vez levam à constituição de novas empresas, criando muitas vezes uma espiral virtuosa.",
      description: ['Dinâmica demográfica','Oportunidades de emprego', 'Habilitações', 'Remunerações']
    },
    {
      id: 4,
      title: "Dinâmicas económicas recentes",
      smallText: "As dinâmicas económicas mais recentes dão indicações extremamente relevantes para perspetivar a evolução do município. Fenómenos tais como a pandemia da COVID-19, a digitalização e descarbonização da economia, bem como as novas empresas a atuar no município podem esculpir a realidade económica da região nos próximos anos.",
      description: ['COVID-19', 'Empresas da região', 'Digitalização', 'Descarbonização da economia']
    }
  ]

  currentId: number = 1
  previousId: number = 0

  constructor() { }

  ngOnInit(): void {
  }

  

}
