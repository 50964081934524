import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { ScrollService } from './services/scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  currentID: string = ""
  scrollY: number = 0
  height: number = 0
  constructor(
    private scrollService: ScrollService,
    private scroller: ViewportScroller,
    private ccService: NgcCookieConsentService
  ) {
      scrollService.currentSection.subscribe(
        (res) => {
          this.currentID = res
        }
      )

      document.addEventListener('scroll', () => {
        this.scrollY = scroller.getScrollPosition()[1]
      })

      this.height = window.innerHeight
  }
}