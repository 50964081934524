<div class="container-fluid header-border d-none d-md-block">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-6">
        <img src="assets/img/logo-header.png" alt="logo-header" routerLink="" class="cursor"> 
      </div>
      <div class="col d-none d-md-flex justify-content-between align-items-center px-0">       
         <h6 class="text-uppercase my-0" (click)="goDown('case-studies')" [ngClass]="{'activeTitle': active == 'case-studies'}">case studies</h6>

        <h6 class="text-uppercase my-0" (click)="goDown('about-us')" [ngClass]="{'activeTitle': active == 'about-us'}">quem somos</h6>
<!--         <h6 class="text-uppercase my-0" (click)="goDown('subscription')" [ngClass]="{'activeTitle': active == 'subscription'}">subscrição</h6>
 -->        <h6 class="text-uppercase my-0" (click)="goDown('region')" [ngClass]="{'activeTitle': active == 'region'}">a região</h6>
        <h6 class="text-uppercase my-0" (click)="goDown('team')" [ngClass]="{'activeTitle': active == 'team'}">a equipa</h6>
      </div>
    </div>
  </div>
</div>
