<div class="container-fluid">
    <div class="container">
        <div class="h2">Formulário de Subscrição</div>

        <h4>Dados Pessoais</h4>
        <div class="row">
            <div class="col-12">
                <label class="form-label">Nome</label>
                <input type="email" class="form-control">

                <label class="form-label">Email</label>
                <input type="email" class="form-control">

                <label class="form-label">Confirmar email</label>
                <input type="email" class="form-control">

                <label class="form-label">Telefone</label>
                <input type="email" class="form-control">

                <label class="form-label">NIF</label>
                <input type="email" class="form-control">
            </div>
        </div>

        <h4>Método de Pagamento - Débito Direto</h4>
        <div class="row">
            <div class="col-12">
                <label class="form-label">Periodicidade</label>
                <select class="form-select" (change)="getValue($event.target)"> 
                    <option default [value]="0">Selecione a periodicidade da subscrição</option>
                    <option *ngFor="let pay of payment" [value]="pay.value">{{pay.name}}</option>
                </select>

                <label class="form-label">Titular</label>
                <input type="email" class="form-control">

                <label class="form-label">IBAN</label>
                <input type="email" class="form-control">

                <h4 *ngIf="currentValue == 20 || currentValue == 240">Valor a pagar: {{currentValue}} euros</h4>
            </div>
        </div>

        <button class="btn btn-primary" (click)="submitSubscription()">Confirmar Subscrição</button>
    </div>
</div>