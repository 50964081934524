<app-title-line sectionName="subscrição"></app-title-line>
<div class="section-even bg-color2 d-flex align-items-center ">
  <div class="container d-flex justify-content-center align-items-center position-relative">
    <div class="row w-100 my-5 my-md-0">
      <div class="col-12 col-md-6 d-flex justify-content-center">
        <div class="imagem-formulario">
          <a href="assets/files/ImagemSubscrição.pdf" target="_blank"><img src="assets/img/imagemSubscricao.webp" alt="subs" class="img-fluid img-border"></a>
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex flex-column justify-content-center mt-4 mt-md-0">
        <h3 class="text-start">Serviço de subscrição que inclui:</h3>
        <ul>
          <li>Disponibilização bimestral de um Boletim, com divulgação de informação económica regional;</li>
          <li>Prestação de esclarecimentos customizados por parte dos nossos especialistas acerca das temáticas abordadas nos Boletins;</li>
          <li>Possibilidade de acesso a uma rede de network.</li>
          <span class="value">Valor: 19,99€/Mês ou 199,99€/Ano</span>
        </ul>
        <div class="d-flex flex-column align-items-center justify-content-center">
          
          <a class="card-button text-center" href="https://www.easypay.pt/form/?f=RegionalEconomics">
            subscrever
          </a> 
          <span class="subLabel text-center mt-1"> <span class="d-none d-md-inline">clique</span> <span class="d-inline d-md-none">carregue</span>  no botão para abrir o formulário de subscrição</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Formulário de Subscrição</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <label class="form-label">Nome</label>
        <input type="email" class="form-control">

        <label class="form-label">Email</label>
        <input type="email" class="form-control">

        <label class="form-label">Confirmar email</label>
        <input type="email" class="form-control">

        <label class="form-label">Telefone</label>
        <input type="email" class="form-control">

        <label class="form-label">NIF</label>
        <input type="email" class="form-control">

        <label class="form-label">Periodicidade</label>
        <select class="form-select" aria-label="Default select example">
          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Limpar</button>
        <button type="button" class="btn btn-form">Guardar</button>
      </div>
    </div>
  </div>
</div>
